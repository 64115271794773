import { useContext } from 'react';
import { MessagesPropsContext } from '../../../context/MessagesPropsContext';
import Messages from '../../../components/Messages';
import { Container } from './styles';

export const FormTemplateMessages = ({ input, hasBorder }) => {
  const [messagesProps] = useContext(MessagesPropsContext);
  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  return (
    <>
      <Container hasBorder={hasBorder}>
        {!isEmptyObject(messagesProps) && (
          <Messages
            activeChatIsCustomer={messagesProps.activeChatIsCustomer}
            messages={input.options}
            currentUserId={messagesProps.currentUserId}
            resend={messagesProps.resend}
            handleImagePopup={messagesProps.handleImagePopup}
            handleVideoPopup={messagesProps.handleVideoPopup}
            customerDataStoreTimezone={messagesProps.customerDataStoreTimezone}
            getMoreMessages={messagesProps.getMoreMessages}
            totalMessages={messagesProps.totalMessages}
            activeStoreId={messagesProps.activeStore?._id?.$oid}
            isFeedbackForm={true}
          />
        )}
      </Container>
    </>
  );
};
export default FormTemplateMessages;
