import FormSelect from '../FormSelect';
import FormText from '../FormText';
import FormSwitch from '../FormSwitch';
import FormTextarea from '../FormTextarea';
import { TextFieldContainer } from '../../FormTemplateSteps/styles';
import FormLabel from '../FormLabel';
import moment from 'moment/moment';
import FormDatetime from '../FormDatetime';
import FormRadioGroup from '../FormRadioGroup';
import { FormAutocompleteFlags } from '../FormAutocompleteFlags';
import FormAutocomplete from '../FormAutocomplete';
import FormAutocompleteMultiple from '../FormAutocompleteMultiple';
import FormAutocompleteIcons from '../FormAutocompleteIcons';
import FormCopyableTextarea from '../FormCopyableTextarea';
import FormWeekDays from '../FormWeekDays';
import FormTimeRange from '../FormTimeRange';
import FormTemplateComponents from '../FormTemplateComponents';
import FormTemplateVariables from '../FormTemplateVariables';
import FormAutocompleteCountries from '../FormAutocompleteCountries';
import FormSelectLabel from '../FormSelectLabel';
import FormAutocompleteLabel from '../FormAutocompleteLabel';
import FormNumber from '../FormNumber';
import FormTemplateMessages from '../FormTemplateMessages';

export const FormElement = ({
  input,
  index,
  inputValues,
  requiredFields,
  inputLabels,
  formRowIndex,
  formRowElementIndex,
  setInputValues,
  setInputLabels,
  formRow,
  copyableItemIndex,
  isPreview,
}) => {
  const handleChange = (prop) => (value) => {
    setInputValues({ ...inputValues, [prop]: value });
    setInputLabels({ ...inputLabels, [prop]: value });
  };

  const handleAutocompleteChange = (prop) => (event) => {
    const val =
      typeof event?.target?.value === 'number'
        ? event?.target?.textContent
        : event?.target?.value ?? event;
    setInputValues({ ...inputValues, [prop]: val });
    setInputLabels({ ...inputLabels, [prop]: val });
  };

  const handleDateChange = (prop) => (event) => {
    setInputValues({ ...inputValues, [prop]: moment(event).format('YYYY-MM-DD HH:mm:ss') });
    setInputLabels({ ...inputLabels, [prop]: moment(event).format('YYYY-MM-DD HH:mm:ss') });
  };
  return (
    <TextFieldContainer key={`FormRow-${index}-${formRowIndex}-${formRowElementIndex}`}>
      <>
        {isPreview && input.type !== 'messages' ? (
          <FormLabel input={input} inputValues={inputValues} inputLabels={inputLabels} />
        ) : (
          <>
            {input.type === 'label' && (
              <FormLabel input={input} inputValues={inputValues} inputLabels={inputLabels} />
            )}
            {input.type === 'select' && (
              <FormSelect
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                inputLabels={inputLabels}
                setInputValues={setInputValues}
                setInputLabels={setInputLabels}
              />
            )}
            {input.type === 'select-label' && (
              <FormSelectLabel
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                inputLabels={inputLabels}
                setInputValues={setInputValues}
                setInputLabels={setInputLabels}
              />
            )}
            {input.type === 'datetime' && (
              <FormDatetime
                input={input}
                inputValues={inputValues}
                handleDateChange={handleDateChange}
              />
            )}
            {input.type === 'text' && (
              <FormText
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                handleChange={handleChange}
              />
            )}
            {input.type === 'number' && (
              <FormNumber
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                handleChange={handleChange}
              />
            )}
            {input.type === 'switch' && (
              <FormSwitch
                input={input}
                inputValues={inputValues}
                setInputValues={setInputValues}
                inputLabels={inputLabels}
                setInputLabels={setInputLabels}
              />
            )}
            {input.type === 'radio' && (
              <FormRadioGroup
                input={input}
                value={inputValues[formRow?.id][copyableItemIndex][input.id]}
                field={formRow?.id}
                itemIndex={copyableItemIndex}
                inputValues={inputValues}
                setInputValues={setInputValues}
              />
            )}
            {input.type === 'autocomplete' && (
              <FormAutocomplete
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                handleAutocompleteChange={handleAutocompleteChange}
              />
            )}
            {input.type === 'autocomplete-label' && (
              <FormAutocompleteLabel
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                handleAutocompleteChange={handleAutocompleteChange}
              />
            )}
            {input.type === 'autocomplete-multiple' && (
              <FormAutocompleteMultiple
                input={input}
                requiredFields={requiredFields}
                inputValues={inputValues}
                setInputValues={setInputValues}
              />
            )}
            {input.type === 'autocomplete-flags' && (
              <FormAutocompleteFlags
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                setInputValues={setInputValues}
              />
            )}
            {input.type === 'autocomplete-countries' && (
              <FormAutocompleteCountries
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                setInputValues={setInputValues}
              />
            )}
            {input.type === 'autocomplete-icons' && (
              <FormAutocompleteIcons
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                setInputValues={setInputValues}
              />
            )}
            {input.type === 'textarea-copyable' && (
              <FormCopyableTextarea
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                setInputValues={setInputValues}
                field={formRow?.id}
                itemIndex={copyableItemIndex}
              />
            )}
            {input.type === 'textarea' && (
              <FormTextarea
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                handleChange={handleChange}
              />
            )}
            {input.type === 'chip-picker-copyable' && (
              <FormWeekDays
                input={input}
                itemIndex={copyableItemIndex}
                field={formRow?.id}
                inputValues={inputValues}
                setInputValues={setInputValues}
              />
            )}
            {input.type === 'time-range-copyable' && (
              <FormTimeRange
                input={input}
                itemIndex={copyableItemIndex}
                field={formRow?.id}
                inputValues={inputValues}
                setInputValues={setInputValues}
              />
            )}
            {input.type === 'template-components' && (
              <FormTemplateComponents
                input={input}
                inputValues={inputValues}
                handleChange={handleChange}
              />
            )}
            {input.type === 'template-variables' && (
              <FormTemplateVariables
                input={input}
                inputValues={inputValues}
                requiredFields={requiredFields}
                handleChange={handleChange}
              />
            )}
            {input.type === 'messages' && (
              <FormTemplateMessages input={input} hasBorder={!isPreview} />
            )}
          </>
        )}
      </>
    </TextFieldContainer>
  );
};
export default FormElement;
