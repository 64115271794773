import styled from 'styled-components';

export const Container = styled.div<{ hasBorder: boolean }>`
  display: flex;
  flex-direction: column;
  height: 400px;
  max-height: 400px;
  padding: 16px 14px;
  border: ${({ hasBorder }) => (hasBorder ? '2px solid rgba(0, 0, 0, 0.6)' : 'none')};
  border-radius: 6px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
