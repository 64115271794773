import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';

export const StepContent = styled.div<{ $isPreview?: boolean; $isPreviewTicket?: boolean }>`
  ${(props) =>
    props.$isPreviewTicket || props.$isPreview
      ? `margin-top: 20px;
      padding: 10px;
      border: 1px solid #79747e;
      border-radius: 6px;`
      : ''}
`;
export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const StyledEditIcon = styled(EditIcon)`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 10px;
  color: #9e9c9c;
  cursor: pointer;
`;
